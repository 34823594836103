<template>
  <page class="base-scrollbar overflow-y-auto h-screen">
    <page-section
      full-width
      bottom-border
    >
      <page-title>
        <template #icon>
          <ic-dashboard :size="32" />
        </template>
        Dashboard
      </page-title>
    </page-section>
    <div class="max-w-full lg:max-w-screen-xl mx-auto">
      <page-section full-width>
        <WelcomeWidget
          class="mb-10 mt-3"
          :site-id="selectedSite.value"
          :user="user"
        />
      </page-section>
      <page-section>
        <div class="flex flex-row gap-2 items-center pt-3">
          <ic-dollar class="text-bb-brand-purple"></ic-dollar>
          <p class="h3 font-bold">Your Targets</p>
        </div>
        <div class="flex flex-col md:flex-row justify-between md:items-center">
          <p class="ml-8">Here’s an overview of your monthly achievements</p>
          <div class="flex flex-row md:gap-6 items-center">
            <div class="text-bb-text-secondary p-2">{{ numDaysRemaining }} days left of {{ selectedMonth.label }}</div>
            <DropdownSelect
              :options="months"
              :selection-value="selectedMonth"
              class="z-10 w-40 bg-white rounded-lg"
              @select-item="selectMonth"
            >
              <template #arrow>
                <div class="absolute right-0 top-0 m-3">
                  <ic-calendar-month :size="18" />
                </div>
              </template>
            </DropdownSelect>
          </div>
        </div>
      </page-section>
      <page-section class="max-w-full lg:max-w-screen-xl mx-auto">
        <ChartWidget
          v-if="!isTargetModuleLoading && !loading && siteMetricsMonthly && sitePerformance"
          :month="selectedMonth.value"
          :site-id="selectedSite.value"
          :site-metrics="siteMetricsMonthly"
          @metrics-updated="refetchSiteData"
        />
        <div
          v-else-if="!isTargetModuleLoading && !loading && !siteMetricsMonthly && !sitePerformance"
          class="bg-neutral-0 rounded-2xl px-20 py-6"
        >
          <div class="flex flex-row justify-center gap-6 max-w-90 mx-auto">
            <img
              src="/content/images/brightbid/sad-bot.svg"
              alt=""
            />
            <p class="text-bb-text-secondary text-left">
              Unfortunately, we have no data available for the selected date range. Please try a different range or
              check back later.
            </p>
          </div>
        </div>
        <brightbid-loader
          v-else
          size="120"
        />
      </page-section>
      <page-section class="max-w-full lg:max-w-screen-xl mx-auto">
        <div class="flex flex-col gap-2 mb-4">
          <div class="flex flex-row gap-2 items-center pt-3">
            <IcStars
              class="text-bb-brand-purple"
              :size="24"
            ></IcStars>
            <p class="h3 font-bold">AI Activity</p>
          </div>
          <div class="flex flex-col md:flex-row justify-between">
            <p class="ml-8 max-w-3xl">Let’s deep-dive into your bids</p>
            <merge-button-round
              button-type="tertiary"
              @click="$router.push({ name: 'site:search:ai:activity:log' })"
            >
              <template #right-icon>
                <ic-chevron
                  class="my-auto"
                  :size="16"
                  :direction="'right'"
                ></ic-chevron>
              </template>
              Full AI Activity Log
            </merge-button-round>
          </div>
        </div>
        <div class="flex flex-col gap-y-6">
          <div
            v-if="isBiddingUpdatesLoading || siteBiddingUpdates.length > 0"
            class="shadow-bb-shadow rounded-2xl"
          >
            <BiddingUpdates
              :bidding-updates="siteBiddingUpdates"
              :is-module-loading="isBiddingUpdatesLoading"
            ></BiddingUpdates>
          </div>
          <div v-else>
            <div class="bg-white rounded-lg px-20 py-6">
              <div class="flex flex-row justify-center gap-6 max-w-90 mx-auto">
                <img
                  src="/content/images/brightbid/sad-bot.svg"
                  alt=""
                />
                <p class="text-bb-text-secondary text-left">
                  Unfortunately, we could not load the AI bidding updates. Please try again later.
                </p>
              </div>
            </div>
          </div>
          <div>
            <div v-if="loading">
              <brightbid-loader size="120" />
            </div>
            <AutopilotCard
              v-if="!loading"
              :autopilot="autopilot"
            ></AutopilotCard>
          </div>
        </div>
      </page-section>

      <BudgetAllocatorBanner class="py-4 px-6" />
      <page-section full-width>
        <TopKwWidget :target-type="targetType" />
      </page-section>
      <page-section full-width>
        <div class="mt-4">
          <div class="flex flex-col gap-2 mb-5">
            <div class="flex flex-row gap-3 items-center">
              <ic-chart-donut class="text-bb-brand-purple"></ic-chart-donut>
              <p class="h3 font-bold">Google Ads Performance</p>
            </div>
            <p class="ml-8 max-w-3xl">
              Here's an overview of your monthly achievements, providing insights into your performance compared to the
              market. Take action based on our tailored suggestions to optimize your results
            </p>
          </div>
          <div v-if="loading">
            <brightbid-loader size="120" />
          </div>
          <div v-if="!loading">
            <div
              v-for="(group, index) in groupedMetrics"
              :key="index"
              class="grid grid-cols-1 gap-4"
              :class="group.length === 3 ? 'mb-4 xl:grid-cols-3' : 'md:grid-cols-2 xl:grid-cols-4'"
            >
              <metrics-widget
                v-for="[key, value] in group"
                :key="key"
                class="border-b"
                :label="key"
                :icon-component="getIconComponent(key)"
                :metric="value"
                :currency="sitePerformance.currency"
              />
            </div>
          </div>
        </div>
      </page-section>
      <page-section full-width>
        <div class="bg-white border rounded-2xl shadow-bb-shadow">
          <div class="flex flex-col gap-2 p-5">
            <div class="flex flex-row gap-3 items-center">
              <div class="bg-neutral-0 rounded-lg p-1 my-auto">
                <ic-funnel
                  class="text-bb-brand-purple h-6 w-6 m-1"
                  :size="24"
                ></ic-funnel>
              </div>
              <p class="h3 font-bold">Conversions Funnel</p>
            </div>
            <p class="max-w-3xl">
              By analyzing these metrics, you can measure how effective your advertising campaign is in converting
              impressions into actual conversions.
            </p>
          </div>
          <div class="p-8">
            <div v-if="loading">
              <brightbid-loader size="120" />
            </div>
            <div v-else>
              <div class="w-87 flex items-center pb-6">
                <search-input
                  v-model="funnelSelectedCampaign"
                  class="w-300"
                  input-id="search-campaign"
                  input-name="search-campaign"
                  placeholder="Search Campaign..."
                  :options="campaignsOptions"
                  :disabled="campaignsOptions.length <= 1"
                  @select-item="selectFunnelCampaign"
                />
              </div>
              <div
                v-if="Object.keys(funnelChartData).length === 0"
                class="bg-white rounded-lg px-20 py-6"
              >
                <div class="flex flex-row justify-center gap-6 max-w-90 mx-auto">
                  <img
                    src="/content/images/brightbid/sad-bot.svg"
                    alt=""
                  />
                  <p class="text-bb-text-secondary text-left">
                    Unfortunately, we have no data available for the selected date range. Please try a different range
                    or check back later.
                  </p>
                </div>
              </div>
              <ConversionFunnel
                v-else
                :data="funnelChartData"
              />
            </div>
          </div>
        </div>
      </page-section>
      <page-section
        full-width
        class="mb-40"
      >
        <div class="flex justify-between items-center mt-4 snap-proximity snap-y">
          <div class="flex gap-3 py-4 pt-8 snap-start">
            <p class="text-xl font-medium">Search Campaigns</p>
            <div
              class="h-8 w-8 flex justify-center items-center rounded-full bg-bb-secondary-purple text-bb-brand-purple"
            >
              <p class="font-bold">{{ tableRows.length }}</p>
            </div>
          </div>
          <merge-button-round
            button-type="tertiary"
            @click="$router.push({ name: 'campaigns' })"
          >
            <template #right-icon>
              <ic-chevron
                class="my-auto"
                :size="16"
                :direction="'right'"
              ></ic-chevron>
            </template>
            See all Campagins
          </merge-button-round>
        </div>
        <div>
          <div v-if="isTableLoading">
            <brightbid-loader size="120" />
          </div>
          <bb-table
            v-else
            ref="bbTable"
            class="border shadow-bb-shadow h-full bg-white"
            :headers="headers"
            :data="tableRows"
            :is-loading="false"
            :default-sorting-order="defaultSortingOrder"
            :scroll-y="true"
            :sticky-header="true"
            max-table-height="calc(100vh - 270px)"
          >
            <template #rows="{ tableData }">
              <campaign-rows
                :data="tableData"
                :hidden-cols="hiddenCols"
              />
            </template>
          </bb-table>
        </div>
      </page-section>
    </div>
  </page>
</template>

<script>
import Toast from '@/components/shared/Toast'
import Page from '@/components/base/page/Page'
import PageSection from '@/components/base/page/PageSection'
import IcChevron from '@/components/icon/ic-chevron.vue'
import IcDashboard from 'vue-material-design-icons/ViewDashboard.vue'
import IcCalendarMonth from 'vue-material-design-icons/CalendarMonth'
import DropdownSelect from '@/components/input/brightbid/DropdownSelect.vue'
import { mapActions, mapState } from 'vuex'
import dayjs from 'dayjs'
import MetricsWidget from '@/components/bbDashboard/MetricWidget.vue'
import AutopilotCard from '@/views/ai_activity_log/cards/AutopilotCard'
import BrightbidLoader from '@/components/loader/BrightbidLoader'
import BbTable from '@/components/table/BbTable.vue'
import CampaignRows from '@/components/table/table_rows/CampaignRows.vue'
import ChartWidget from '@/components/bbDashboard/ChartWidget'
import WelcomeWidget from '@/components/bbDashboard/WelcomeWidget.vue'
import IcStars from '@/components/icon/brightbid/ic-stars.vue'
import IcDollar from '@/components/icon/ic-dollar.vue'
import BiddingUpdates from '@/components/bbDashboard/BiddingUpdates.vue'
import TopKwWidget from '@/components/bbDashboard/TopKwWidget.vue'
import IcChartDonut from 'vue-material-design-icons/ChartDonut'
import ConversionFunnel from '@/components/chart/bbcharts/charts/FunnelChart.vue'
import IcFunnel from '@/components/icon/ic-funnel.vue'
import SearchInput from '@/components/input/brightbid/SearchInput'
import BudgetAllocatorBanner from '@/components/dashboard/BudgetAllocatorBanner.vue'

const NOW = new Date()
export default {
  name: 'BbDashboard',
  components: {
    // eslint-disable-next-line
    Toast,
    Page,
    PageSection,
    IcChevron,
    IcDashboard,
    IcCalendarMonth,
    MetricsWidget,
    DropdownSelect,
    BrightbidLoader,
    AutopilotCard,
    CampaignRows,
    BbTable,
    ChartWidget,
    WelcomeWidget,
    IcStars,
    IcDollar,
    BiddingUpdates,
    TopKwWidget,
    IcChartDonut,
    ConversionFunnel,
    IcFunnel,
    SearchInput,
    BudgetAllocatorBanner,
  },
  data() {
    const NOW = new Date()
    const currentYear = NOW.getFullYear()
    const currentMonth = NOW.getMonth()
    const isTodayFirstOfMonth = NOW.getDate() === 1
    const selectedMonthValue = isTodayFirstOfMonth ? currentMonth : currentMonth + 1
    const monthLabel = new Date(currentYear, selectedMonthValue - 1).toLocaleString('en-US', { month: 'long' })

    return {
      monthLabel,
      loading: false,
      isTargetModuleLoading: true,
      isTableLoading: true,
      isBiddingUpdatesLoading: false,
      months: this.getMonthsOptions(),
      selectedMonth: {
        label: monthLabel,
        value: selectedMonthValue,
        daysRemaining: this.calculateDaysRemaining(selectedMonthValue),
      },
      siteId: null,
      siteMetrics: {},
      siteMetricsMonthly: {},
      autopilot: this.getDefaultAutopilot(),
      headers: [
        {
          value: 'campaign_name',
          label: 'Campaign Name',
          sortable: true,
          sorting: 'DESCENDING',
          position: 'left',
        },
        {
          value: 'bid_strategy',
          label: 'Bid Strategy',
          sortable: true,
          sorting: null,
          position: 'left',
        },
        {
          value: 'target',
          label: 'Target',
          sortable: true,
          sorting: null,
          position: 'right',
        },
        {
          value: 'budget',
          label: 'Budget',
          sortable: true,
          sorting: null,
          position: 'right',
        },
        {
          value: 'impressions',
          label: 'Impressions',
          sortable: true,
          sorting: null,
          position: 'right',
        },
        {
          value: 'cost',
          label: 'Cost',
          sortable: true,
          sorting: 'DESCENDING',
          position: 'right',
        },
        {
          value: 'clicks',
          label: 'Clicks',
          sortable: true,
          sorting: null,
          position: 'right',
        },
        {
          value: 'avg_cpc',
          label: 'Avg. CPC',
          sortable: true,
          sorting: null,
          position: 'right',
        },
        {
          value: 'conversions',
          label: 'Conv.',
          sortable: true,
          sorting: null,
          position: 'right',
        },
        {
          value: 'conv_rate',
          label: 'Conv. Rate',
          sortable: true,
          sorting: null,
          position: 'right',
        },
        {
          value: 'cost_conv',
          label: 'Cost/Conv',
          sortable: true,
          sorting: null,
          position: 'right',
        },
        {
          value: 'ctr',
          label: 'CTR',
          sortable: true,
          sorting: null,
          position: 'right',
        },
        {
          value: 'roas',
          label: 'ROAS',
          sortable: true,
          sorting: null,
          position: 'right',
        },
      ],
      hiddenCols: ['channel', 'action', 'autopilot'],
      tableRows: [],
      defaultSortingOrder: { order: 'DESCENDING', column: 'cost' },
      funnelSelectedCampaign: { label: 'All Campaigns', value: 'all' },
      siteCampaigns: [],
      funnelChartData: {},
    }
  },
  computed: {
    ...mapState('site', [
      'selectedSite',
      'sitePerformance',
      'siteCampaignsPerformance',
      'siteBiddingUpdates',
      'siteCampaignsPerformanceFunnel',
    ]),
    ...mapState('auth', ['user']),
    numDaysRemaining() {
      return this.calculateDaysRemaining(this.selectedMonth.value)
    },
    filteredHeaders() {
      return this.headers.filter(header => header.text !== 'Autopilot')
    },
    groupedMetrics() {
      const entries = Object.entries(this.siteMetrics)
      const groups = []
      let groupSize = 3

      for (let i = 0; i < entries.length; i += groupSize) {
        if (i !== 0) {
          groupSize = groupSize === 3 ? 4 : 3
        }
        groups.push(entries.slice(i, i + groupSize))
      }
      return groups
    },
    targetType() {
      return this.siteMetricsMonthly?.site_metric_targets?.roas > 0 ? 'roas' : 'cpa'
    },
    campaignsOptions() {
      return [
        { label: 'All Campaigns', value: 'all' },
        ...this.siteCampaignsPerformanceFunnel.map(campaign => ({
          label: campaign.campaign_name,
          value: campaign.id,
        })),
      ]
    },
  },
  watch: {
    async selectedSite(newVal, oldVal) {
      if (newVal && newVal.value !== oldVal?.value) {
        this.loading = true
        await Promise.all([
          this.fetchMonthlySiteMetrics(),
          this.fetchSiteMetrics(),
          this.getAutopilotData(),
          this.fetchCampaignMetrics(),
          this.getAIBiddingUpdates(),
          this.getCampaignsPerformanceFunnelData(),
        ])
        this.funnelChartData = this.sitePerformance
        this.loading = false
      }
    },
  },
  async mounted() {
    this.loading = true
    await Promise.all([
      this.fetchMonthlySiteMetrics(),
      this.fetchSiteMetrics(),
      this.getAutopilotData(),
      this.fetchCampaignMetrics(),
      this.getAIBiddingUpdates(),
      this.getCampaignsPerformanceFunnelData(),
    ])
    this.funnelChartData = this.sitePerformance
    this.loading = false
  },
  methods: {
    ...mapActions({
      fetchSitePerformance: 'site/fetchSitePerformance',
      fetchSiteCampaignsPerformanceData: 'site/fetchSiteCampaignsPerformanceData',
      getSitePerformanceMonthly: 'site/getSitePerformanceMonthly',
      getSiteCampaignsPerformance: 'site/getSiteCampaignsPerformance',
      fetchSiteBiddingUpdates: 'site/fetchSiteBiddingUpdates',
      fetchCampaignsPerformanceFunnelData: 'site/fetchCampaignsPerformanceFunnelData',
    }),
    refetchSiteData() {
      this.fetchMonthlySiteMetrics()
      this.fetchSiteMetrics()
    },
    getMonthsOptions() {
      const months = []
      for (let i = 0; i < 12; i++) {
        const month = new Date(NOW.getFullYear(), i, 1)
        months.push({
          label: month.toLocaleString('en-US', { month: 'long' }),
          value: month.getMonth() + 1,
          isDisabled: month > NOW,
        })
      }
      return months
    },
    async selectMonth(month) {
      this.selectedMonth = month
      await this.fetchMonthlySiteMetrics()
    },
    getDateRanges() {
      const today = dayjs()
      const endDate = today.subtract(1, 'day').format('YYYY-MM-DD')
      const startDate = today.subtract(30, 'days').format('YYYY-MM-DD')
      const compareEndDate = today.subtract(31, 'day').format('YYYY-MM-DD')
      const compareStartDate = today.subtract(60, 'days').format('YYYY-MM-DD')

      return { startDate, endDate, compareStartDate, compareEndDate }
    },
    getDefaultAutopilot() {
      return {
        summary: {
          total: 0,
          positive: 0,
          negative: 0,
          pause: 0,
          totalSuggestedKeywords: 0,
        },
        actions: [],
        autopilotStatus: false,
        allCampaignsSelected: true,
        keywordSuggestionsEnabled: false,
      }
    },
    async getAutopilotData() {
      try {
        this.loading = true
        this.siteId = this.selectedSite.value

        const yesterday = dayjs().subtract(1, 'day').format('YYYY-MM-DD')
        const thirtyoneDaysAgo = dayjs().subtract(31, 'day').format('YYYY-MM-DD')

        const url = `/search/site/${this.siteId}/ai-log/autopilot?start_date=${thirtyoneDaysAgo}&end_date=${yesterday}`
        const { data } = await this.$http.get(url)

        const {
          summary,
          data: actions,
          autopilot_enabled: autopilotStatus,
          keyword_suggestions_enabled: keywordSuggestionsEnabled,
        } = data

        this.autopilot = {
          summary: {
            total: summary.total,
            positive: summary.positive,
            negative: summary.negative,
            pause: summary.pause,
            totalSuggestedKeywords: summary.total_suggested_keywords,
          },
          actions: actions ?? [],
          autopilotStatus,
          keywordSuggestionsEnabled,
        }
      } catch (error) {
        this.autopilot = this.getDefaultAutopilot()
        console.error('Error fetching autopilot data')
      } finally {
        this.loading = false
      }
    },
    async fetchSiteMetrics() {
      this.loading = true
      const { startDate, endDate, compareStartDate, compareEndDate } = this.getDateRanges()

      await this.fetchSitePerformance({
        siteId: this.selectedSite.value,
        startDate,
        endDate,
        compareStartDate,
        compareEndDate,
      })

      const { clicks, cost, conversions, cpc, impressions, ctr, conversion_rate } = this.sitePerformance

      const metrics = {
        impressions,
        clicks,
        cost,
        conversions,
        'average CPC': cpc,
        CTR: ctr,
        'conversion rate': conversion_rate,
      }

      this.siteMetrics = metrics
      this.loading = false
    },
    async fetchCampaignMetrics() {
      const { startDate, endDate, compareStartDate, compareEndDate } = this.getDateRanges()

      this.isTableLoading = true
      try {
        const { data: campaigns } = await this.$http.get(`/search/site/${this.selectedSite.value}/campaigns`)
        this.siteCampaigns = campaigns

        await this.fetchSiteCampaignsPerformanceData({
          siteId: this.selectedSite.value,
          startDate,
          endDate,
          compareStartDate,
          compareEndDate,
          channel: 'search',
        })

        const filteredCampaigns = this.siteCampaignsPerformance.filter(
          campaign => campaign.status === 'live' || campaign.cost > 0,
        )

        this.tableRows = filteredCampaigns
      } catch (error) {
        console.error('Error fetching site campaigns performance', error)
      } finally {
        this.isTableLoading = false
      }
    },
    async fetchMonthlySiteMetrics() {
      this.isTargetModuleLoading = true
      const month = this.selectedMonth.value
      const year = new Date().getFullYear()
      const endDate = dayjs()
        .year(year)
        .month(month - 1)
        .endOf('month')
        .format('YYYY-MM-DD')
      const startDate = dayjs()
        .year(year)
        .month(month - 1)
        .date(1)
        .format('YYYY-MM-DD')

      const params = {
        siteId: this.selectedSite.value,
        startDate,
        endDate,
        month,
      }
      this.siteMetricsMonthly = await this.getSitePerformanceMonthly(params)
      this.isTargetModuleLoading = false
    },
    async getAIBiddingUpdates() {
      this.isBiddingUpdatesLoading = true
      try {
        await this.fetchSiteBiddingUpdates(this.selectedSite.value)
      } catch (error) {
        console.error('Error fetching AI bidding updates')
      } finally {
        this.isBiddingUpdatesLoading = false
      }
    },
    calculateDaysRemaining(month) {
      const currentYear = new Date().getFullYear()
      const currentDate = new Date()
      const selectedDate = new Date(currentYear, month - 1, 1)
      const totalDaysInMonth = new Date(currentYear, month, 0).getDate()

      if (currentDate.getMonth() + 1 === month) {
        if (currentDate.getDate() === 1) {
          return totalDaysInMonth
        }
        return totalDaysInMonth - currentDate.getDate()
      } else if (selectedDate > currentDate) {
        return totalDaysInMonth
      } else {
        return 0
      }
    },
    getIconComponent(label) {
      const iconComponents = {
        impressions: 'IcEye',
        clicks: 'IcClicks',
        'conversion rate': 'IcCurrencyExchange',
        cost: 'IcDollarCircle',
        conversions: 'IcDollar',
        CTR: 'IcCtr',
        'average CPC': 'IcAverageCpc',
      }
      return iconComponents[label] || null
    },
    async getCampaignsPerformanceFunnelData() {
      const { startDate, endDate, compareStartDate, compareEndDate } = this.getDateRanges()
      try {
        await this.fetchCampaignsPerformanceFunnelData({
          siteId: this.selectedSite.value,
          startDate,
          endDate,
          compareStartDate,
          compareEndDate,
          channel: 'search',
        })
      } catch (error) {
        this.$toast.error({
          component: Toast,
          props: {
            title: 'Error',
            message: 'Error fetching campaign metrics for funnel chart',
            type: 'error',
          },
        })
        console.error('Error fetching campaign metrics for funnel chart', error)
      }
    },
    selectFunnelCampaign(campaign) {
      if (campaign.value === 'all') {
        this.funnelChartData = this.sitePerformance
      } else {
        this.funnelChartData = this.siteCampaignsPerformanceFunnel.filter(item => item.id === campaign.value)[0]
      }
    },
  },
}
</script>
<style lang="scss">
.table-container {
  height: calc(100vh - 280px);
}
</style>
