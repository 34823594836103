<template>
  <tbody class="divide-y divide-bb-neutral-50 overflow-y-scroll">
    <tr v-if="data.length === 0">
      <td colspan="13">
        <div class="mx-auto my-20 flex flex-col items-center justify-center">
          <img
            class="w-24 h-auth"
            src="/content/images/illustrations/binoculars.png"
            alt="dashboard"
          />
          <p class="my-2 w-100 text-center">
            We couldn’t find any campaigns matching your search. If you need help
            <span class="text-bb-brand-purple underline">contact us</span>.
          </p>
        </div>
      </td>
    </tr>
    <tr
      v-for="(item, index) in tableData"
      :key="index"
      class="group h-18 whitespace-no-wrap font-normal w-full text-sm"
      :class="hoveredRow === index ? 'bg-bb-background-purple-0' : ''"
      @mouseenter="handleHoverRow(index)"
    >
      <td
        class="px-4 whitespace-no-wrap sticky left-0"
        :class="hoveredRow === index ? 'bg-bb-background-purple-0' : 'bg-white'"
      >
        <div class="flex items-center gap-2">
          <status
            :status="item.status"
            :show-status="false"
          />
          <p
            class="max-w-xs truncate"
            :title="item.campaign_name"
          >
            {{ item.campaign_name }}
          </p>
        </div>
      </td>
      <td
        v-if="'autopilot' in item"
        class="px-4 text-center"
      >
        <div class="flex items-center justify-center gap-1">
          <ic-stars
            size="20"
            :value="item.autopilot ? 'on' : 'off'"
          />
          <p v-if="item.autopilot">On</p>
          <p v-else>Off</p>
        </div>
      </td>
      <td
        v-if="!hiddenCols.includes('channel')"
        class="px-4 text-center"
      >
        <div v-if="item.is_performance_max_bid_strategy">
          <ic-pmax
            size="20"
            class="mx-auto"
          />
        </div>

        <div v-else-if="item.channel === 'search'">
          <google-search-tag
            size="20"
            class="mx-auto"
          />
        </div>
        <div v-else-if="item.channel === 'shopping'">
          <google-shopping-tag
            size="20"
            class="mx-auto"
          />
        </div>
        <div v-else>
          <p>-</p>
        </div>
      </td>
      <td class="px-4">
        <div class="flex items-center justify-start gap-2">
          <solid-brightbid-logo v-if="item.is_brightbid_bid_strategy" />
          <ic-pmax v-else-if="item.is_performance_max_bid_strategy" />
          <google-g-icon v-else-if="item.bid_strategy_display_name" />
          <div class="flex">
            {{ item.bid_strategy_display_name }}
            <div
              v-if="item.brightbid_google_strategy_mismatch"
              @click="$emit('selectBidStrategyMismatch', item)"
            >
              <IcInfoSolid
                :size="12"
                class="text-bb-disabled-buttons ml-2 mt-1 cursor-pointer"
              />
            </div>
          </div>
          <div @click="handleEditStrategy($event, item)">
            <ic-pencil
              v-if="editable && !item.brightbid_google_strategy_mismatch"
              :size="16"
              :class="{ invisible: hoveredRow !== index }"
              class="my-auto group-hover:visible text-bb-disabled-gray cursor-pointer p-2 hover:text-bb-brand-purple"
            ></ic-pencil>
          </div>
        </div>
      </td>
      <td class="px-4 pr-6 text-right">
        <p>{{ item.targetValue }} {{ item.targetValue ? item.target_type : '' }}</p>
      </td>
      <td class="px-4 pr-4">
        <div class="flex gap-x-1 justify-end">
          <p>
            {{ item.budget === 'Unlimited' ? 'Unlimited' : `${item.budget} ${item.currency}/Day` }}
          </p>
          <new-tooltip
            v-if="tooltip"
            direction="right-start"
            :theme="tooltipTheme"
            class="pb-1"
          >
            <template #content>
              {{ item.budgetMonthly }}
            </template>
          </new-tooltip>
        </div>
      </td>
      <td class="px-4 pr-6 text-right">
        <p>{{ item.impressions || '-' }}</p>
      </td>
      <td class="px-4 pr-6 text-right">
        <p>{{ item.cost || '-' }} {{ item.currency }}</p>
      </td>
      <td
        v-if="!metricsLinkedStatus.includes(item.status.toLowerCase())"
        colspan="7"
        class="px-6"
      >
        <div class="flex items-center gap-4">
          <ic-info-solid
            class="text-bb-brand-purple"
            :size="16"
          />
          <div
            v-if="item.status === 'preparing'"
            class="break-words w-60"
          >
            <p>
              <span class="font-bold">Generating bids:</span>
              This can take up to 15 minutes.
            </p>
            <p>You cannot make any changes during the campaign generation phase.</p>
          </div>

          <div
            v-if="item.status === 'draft'"
            class="break-words w-60 flex items-center"
          >
            <p>
              <span class="font-bold">Draft:</span>
              Finish editing the campaign for it to be launched
            </p>
            <ic-chevron
              direction="right"
              class="text-bb-brand-purple"
            />
          </div>
        </div>
      </td>
      <td
        v-if="metricsLinkedStatus.includes(item.status.toLowerCase())"
        class="px-4 pr-6 text-right"
      >
        <p>{{ item.clicks || '-' }}</p>
      </td>
      <td
        v-if="metricsLinkedStatus.includes(item.status.toLowerCase())"
        class="px-4 pr-6 text-right"
      >
        <p v-if="item.avg_cpc">{{ item.avg_cpc }} {{ item.currency }}</p>
        <p v-else>-</p>
      </td>
      <td
        v-if="metricsLinkedStatus.includes(item.status.toLowerCase())"
        class="px-4 pr-6 text-right"
      >
        <p>{{ item.conversions || '-' }}</p>
      </td>
      <td
        v-if="metricsLinkedStatus.includes(item.status.toLowerCase())"
        class="px-4 pr-6 text-right"
      >
        <p v-if="item.conv_rate">{{ item.conv_rate }}%</p>
        <p v-else>-</p>
      </td>
      <td
        v-if="metricsLinkedStatus.includes(item.status.toLowerCase())"
        class="px-4 pr-6 text-right"
      >
        <p v-if="item.cost_conv">{{ item.cost_conv || '-' }} {{ item.currency }}</p>
        <p v-else>-</p>
      </td>
      <td
        v-if="metricsLinkedStatus.includes(item.status.toLowerCase())"
        class="px-4 pr-6 text-right"
      >
        <p v-if="item.ctr">{{ item.ctr }}%</p>
        <p v-else>-</p>
      </td>
      <td
        v-if="metricsLinkedStatus.includes(item.status.toLowerCase())"
        class="px-4 text-right"
      >
        <p>{{ item.roas || '-' }}%</p>
      </td>

      <td
        v-if="!hiddenCols.includes('action')"
        class="px-4"
      >
        <div class="flex justify-end">
          <kebab
            v-if="hoveredRow === index"
            :item-data="{}"
            :menu="menu"
            menu-position="left"
          >
            <template #menu>
              <div
                v-for="action in menu"
                :key="action.value"
                class="py-2 px-4 cursor-pointer hover:bg-bb-secondary-purple"
              >
                <div class="flex items-center gap-2">
                  <component :is="action.icon" />
                  <p>{{ action.label }}</p>
                </div>
              </div>
            </template>
          </kebab>
        </div>
      </td>
    </tr>
  </tbody>
</template>

<script>
import Kebab from '@/components/icon/brightbid/kebab.vue'
import Status from '@/components/shared/Status.vue'
import GoogleSearchTag from '@/components/icon/brightbid/google-search-tag.vue'
import GoogleShoppingTag from '@/components/icon/brightbid/google-shopping-tag.vue'
import SolidBrightbidLogo from '@/components/icon/brightbid/solid-brightbid-logo.vue'
import GoogleGIcon from '@/components/icon/brightbid/google-g-icon.vue'
import IcLive from '@/components/icon/brightbid/ic-live.vue'
import IcEdit from '@/components/icon/brightbid/ic-edit.vue'
import IcPause from '@/components/icon/brightbid/ic-pause.vue'
import IcInfoSolid from '@/components/icon/brightbid/ic-info-solid.vue'
import IcChevron from '@/components/icon/ic-chevron.vue'
import IcStars from '@/components/icon/ic-stars'
import NewTooltip from '@/components/alert/NewTooltip'
import IcPencil from 'vue-material-design-icons/Pencil.vue'
import IcPmax from '@/components/icon/ic-pmax.vue'

export default {
  name: 'CampaignRows',
  components: {
    Kebab,
    Status,
    GoogleSearchTag,
    GoogleShoppingTag,
    SolidBrightbidLogo,
    GoogleGIcon,
    IcPause,
    IcLive,
    IcEdit,
    IcInfoSolid,
    IcChevron,
    IcStars,
    NewTooltip,
    IcPencil,
    IcPmax,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
    hiddenCols: {
      type: Array,
      default: () => [],
      required: false,
    },
    editable: {
      type: Boolean,
      default: false,
      required: false,
    },
    editActive: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      menu: [
        { label: 'Edit', value: 'edit', icon: 'IcEdit' },
        { label: 'Pause', value: 'pause', icon: 'IcPause' },
        { label: 'Live', value: 'live', icon: 'IcLive' },
      ],
      metricsLinkedStatus: ['live', 'paused', 'enabled'],
      hoveredRow: null,
      tooltipTheme: 'sm',
      tooltip: true,
    }
  },
  computed: {
    numdays() {
      return new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getDate()
    },
    tableData() {
      const numdays = this.numdays
      const formatNumberWithSpaces = this.formatNumberWithSpaces
      const getTargetValue = this.getTargetValue

      return this.data.map(item => {
        const budgetMonthly = formatNumberWithSpaces(item.budget * numdays)
        return {
          ...item,
          budgetMonthly: `${budgetMonthly} ${item.currency}/Month`,
          targetValue: getTargetValue(item),
          budget: formatNumberWithSpaces(item.budget),
          impressions: formatNumberWithSpaces(item.impressions),
          cost: formatNumberWithSpaces(item.cost),
          clicks: formatNumberWithSpaces(item.clicks),
          avg_cpc: formatNumberWithSpaces(item.avg_cpc),
          conversions: formatNumberWithSpaces(item.conversions),
          conv_rate: formatNumberWithSpaces(item.conv_rate),
          cost_conv: formatNumberWithSpaces(item.cost_conv),
          ctr: formatNumberWithSpaces(item.ctr),
          roas: formatNumberWithSpaces(item.roas),
        }
      })
    },
  },
  watch: {
    hoveredRow(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$emit('closeEditStrategyModal')
      }
    },
  },
  methods: {
    formatNumber(num) {
      return num ? parseFloat(parseFloat(num).toFixed(2)) : 0
    },
    formatNumberWithSpaces(num) {
      return this.formatNumber(num)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    },
    getTargetValue(item) {
      if (!item.target_value || item.target_value == 0) return ''

      const isRoas = ['ROAS', 'TARGET_ROAS', 'TARGET ROAS'].includes(item?.target_type?.toUpperCase())
      const formattedValue = this.formatNumberWithSpaces(item.target_value * (isRoas ? 100 : 1))
      const unit = isRoas ? '%' : ` ${item.currency}`

      return `${formattedValue}${unit}`
    },
    handleEditStrategy(event, item) {
      const rect = event.target.getBoundingClientRect()
      this.$emit('editBiddingStrategy', {
        item,
        position: {
          top: rect.top,
          left: rect.left,
          bottom: rect.bottom,
          right: rect.right,
        },
      })
    },
    handleHoverRow(index) {
      if (!this.editActive) {
        this.hoveredRow = index
      }
    },
  },
}
</script>
<style scoped lang="scss">
.group:hover .group-hover\:visible {
  visibility: visible;
}
</style>
